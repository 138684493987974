import { ChartOptions } from './chart-option.query';

export const PIE_CHART_OPTION: Partial<ChartOptions> = {
  series: [25, 15, 44, 55, 41],
  chart: {
    type: 'pie',
    width: '80%',
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -5,
      },
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
  labels: ['overIndex', 'index', 'nearIndex', 'noIndex', 'underIndex'],
  dataLabels: {
    enabled: true,
    dropShadow: {
      enabled: false,
    },
  },
  colors: ['#0063ff', '#3677FA', '#4A33FF', '#ac45fc', '#9117fa'],
  title: {
    text: '',
    align: 'center',
    style: {
      fontSize: '24px',
      fontWeight: 600,
      fontFamily: 'Poppins,sans-serif!important',
      color: '#111',
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};
