import { Inject, Injectable } from '@angular/core';
import { UserAssignedToTrackingEvent, UserChangedEvent } from '@app.cobiro.com/core/events';
import { LocalStorage } from '@app.cobiro.com/core/storage';
import { TrackingService } from '@app.cobiro.com/tracking';
import { GETS_USER_FROM_TOKEN, GetsUserFromToken } from '@app.cobiro.com/user/core';
import { GETS_DETAILS, GetsDetails } from '@app.cobiro.com/user/settings';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthData, LOGINS, LoginsUser } from '../domain/logins';
import { User } from '../domain/user/user';
import { AuthStorage } from '../infrastructure/auth.storage';

export interface Auth {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn?: number;
  refreshing?: boolean;
}

export interface UserData {
  firstName: string;
  lastName: string;
}

export interface Verification {
  email: string;
  password: string;
  isPending: boolean;
}

export interface UpdateUserCommand {
  firstName: string;
  lastName: string;
  avatar: string;
  phoneNumber: string;
  country: string;
}

@Injectable()
export class UserState {
  private _auth = new BehaviorSubject<Auth>(null);
  private auth$: Observable<Auth> = this._auth.asObservable().pipe(filter(auth => auth !== null));
  accessToken$: Observable<string> = this.auth$.pipe(map(auth => auth.accessToken));

  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private loginSubject = new BehaviorSubject<AuthData>(null);
  login$: Observable<AuthData> = this.loginSubject
    .asObservable()
    .pipe(filter(login => login !== null));

  constructor(
    @Inject(LOGINS) private readonly _logins: LoginsUser,
    private readonly _authStorage: AuthStorage,
    private readonly _trackingService: TrackingService,
    private readonly _localStorageService: LocalStorage,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    @Inject(GETS_USER_FROM_TOKEN) private readonly _getsUser: GetsUserFromToken,
    @Inject(GETS_DETAILS) private readonly _getsDetails: GetsDetails,
  ) {
    this.user$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.id === curr.id && prev.email === curr.email;
        }),
        map(user => User.fromRaw(user)),
        tap(user => {
          this._applicationBus.dispatch(
            new UserChangedEvent({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              isGuest: user.isGuest,
              createdAt: new Date(user.createdAt),
              avatar: user.avatar,
            }),
          );
        }),
      )
      .subscribe();
  }

  get user$(): Observable<User> {
    return this._user.pipe(filter(user => !!user));
  }

  getAuth(): Observable<Auth> {
    return this._authStorage.getAuth().pipe(shareReplay(1));
  }

  getCurrent(): Observable<User> {
    const token = this._getsUser.get();

    return this._getsDetails.get().pipe(
      map(
        details =>
          new User(
            token.userId,
            details.firstName,
            details.lastName,
            details.email,
            details.group,
            null,
            null,
            new Date(details.createdAt * 1000).toDateString(),
            details.avatar,
          ),
      ),
      tap((user: User) => this._user.next(user)),
      shareReplay(1),
    );
  }

  login(email: string, password: string): Observable<AuthData> {
    return this._logins.login(email, password).pipe(
      take(1),
      tap((login: AuthData) => {
        const { id } = login;

        const auth = { ...login, refreshing: false };
        this._authStorage.setAuth(auth);
        this._applicationBus.dispatch(new UserAssignedToTrackingEvent(id));

        this._trackingService.login({ id, email });
        this.loginSubject.next(login);
      }),
      shareReplay(1),
    );
  }

  setAuth(userId: string, auth: Auth): void {
    const login: AuthData = {
      id: userId,
      refreshToken: auth.refreshToken,
      tokenType: auth.tokenType,
      accessToken: auth.accessToken,
      expiresIn: auth.expiresIn,
    };

    this._authStorage.setAuth(auth);
    this.loginSubject.next(login);
  }

  tokenLogin(token: string, refreshToken?: string): Observable<boolean> {
    this._authStorage.updateToken(token, refreshToken);

    return this.getCurrent().pipe(
      take(1),
      switchMap((user: User) => {
        const { id, email } = user;

        this._applicationBus.dispatch(new UserAssignedToTrackingEvent(id));
        this._trackingService.login({ id, email });
        this._trackingService.event('/Hub - Account - AuthData successful - v1');
        return of(true);
      }),
      catchError(_ => {
        this._localStorageService.destroyAll();
        return of(false);
      }),
      shareReplay(1),
    );
  }

  setAuthTokens(accessToken: string, refreshToken: string | null) {
    this._authStorage.setAuth({
      accessToken,
      refreshToken,
      tokenType: 'Bearer',
    });
  }

  updateUserData(command: UpdateUserCommand) {
    const { firstName, lastName, avatar, country, phoneNumber } = command;
    this._user.pipe(take(1)).subscribe(user => {
      const newUser = User.fromRaw({ ...user, firstName, lastName, avatar, country, phoneNumber });
      this._user.next(newUser);
      this._applicationBus.dispatch(
        new UserChangedEvent({
          id: newUser.id,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          isGuest: newUser.isGuest,
          createdAt: new Date(newUser.createdAt),
          email: newUser.email,
          avatar: newUser.avatar,
        }),
      );
    });
  }
}
