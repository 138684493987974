/* eslint-disable max-lines-per-function */
import { Inject, Injectable } from '@angular/core';
import { CobiroProTeamsLoadedEvent, TeamSelectedEvent } from '@app.cobiro.com/core/events';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { GetsAllTeamQueryPort } from '../ports/primary/gets-all-team-query.port';
import { TeamQuery } from '../ports/primary/team.query';
import { filter, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { TeamDTO } from '../ports/secondary/team.dto';
import { GETS_ALL_TEAMS_DTO, GetsAllTeamsDtoPort } from '../ports/secondary/gets-all-team-dto.port';
import { HasTeamQueryPort } from '../ports/primary/has-team-query.port';
import { SetCurrentTeamCommand } from '../ports/primary/set-current-team.command';
import { SetCurrentTeamCommandPort } from '../ports/primary/set-current-team-command.port';
import { GetsCurrentTeamQueryPort } from '../ports/primary/gets-current-team-query.port';
import { APPLICATION_BUS, ApplicationBus } from '@cobiro/eda';
import { LoadTeamsCommandPort } from '../ports/primary/load-teams-command.port';
import {
  GETS_COBIRO_PRO_CONTEXT_QUERY,
  GetsCobiroProContextQueryPort,
  SETS_SELECTED_TEAM_COMMAND,
  SetsSelectedTeamCommandPort,
  CLEAR_CONTEXT_COMMAND,
  ClearsContextCommandPort,
  SetsSelectedWorkspaceCommandPort,
  SETS_SELECTED_WORKSPACE_COMMAND,
} from '@app.cobiro.com/cobiro-pro/context';
import {
  CobiroProTeamsLoadedDispatcherPort,
  TEAMS_LOADED_DISPATCHER,
} from '../ports/secondary/cobiro-pro-teams-loaded.dispatcher-port';

@Injectable()
export class TeamsState
  implements
    GetsAllTeamQueryPort,
    HasTeamQueryPort,
    SetCurrentTeamCommandPort,
    GetsCurrentTeamQueryPort,
    LoadTeamsCommandPort
{
  private _selectedTeamSubject$: BehaviorSubject<TeamDTO | null> = new BehaviorSubject(null);

  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  private _allTeamsSubject$: BehaviorSubject<TeamDTO[] | null> = new BehaviorSubject(null);
  private _allTeams$ = this._allTeamsSubject$.asObservable();

  constructor(
    @Inject(GETS_ALL_TEAMS_DTO) private readonly _getAllTeam: GetsAllTeamsDtoPort,
    @Inject(APPLICATION_BUS) private readonly _applicationBus: ApplicationBus,
    @Inject(GETS_COBIRO_PRO_CONTEXT_QUERY)
    private readonly _getsCobiroProContextQuery: GetsCobiroProContextQueryPort,
    @Inject(SETS_SELECTED_TEAM_COMMAND)
    private readonly _setsSelectedTeamCommand: SetsSelectedTeamCommandPort,
    @Inject(SETS_SELECTED_WORKSPACE_COMMAND)
    private readonly _setsSelectedWorkspaceCommand: SetsSelectedWorkspaceCommandPort,
    @Inject(TEAMS_LOADED_DISPATCHER)
    private readonly _teamsLoadedDispatcher: CobiroProTeamsLoadedDispatcherPort,
    @Inject(CLEAR_CONTEXT_COMMAND)
    private readonly _clearContexCommand: ClearsContextCommandPort,
  ) {}

  loadAll(): Observable<TeamDTO[]> {
    return this._load();
  }

  loadByCriteria(criteria: { [p: string]: number | string }): Observable<TeamDTO[]> {
    return this._load(criteria);
  }

  setCurrentTeam(command: SetCurrentTeamCommand): Observable<void> {
    const currentTeam = this._selectedTeamSubject$.getValue();
    if (currentTeam?.id === command.teamId) {
      this._applicationBus.dispatch(new TeamSelectedEvent(command.teamId));
      return of(void 0);
    }

    return this._allTeams$.pipe(
      filter(t => !!t),
      take(1),
      mergeMap((teams: TeamDTO[]) => {
        const selectedTeam = teams.find(team => team.id === command.teamId);
        this._selectedTeamSubject$.next(selectedTeam);
        return zip([
          this._setsSelectedWorkspaceCommand.setSelectedWorkspace(null, null, null, null),
          this._setsSelectedTeamCommand.setSelectedTeam(
            selectedTeam.id,
            selectedTeam.name,
            selectedTeam.role,
            selectedTeam.avatar,
          ),
        ]).pipe(map(() => selectedTeam.id));
      }),
      tap((selectedTeamId: string) => {
        console.info('selectedTeamId ', selectedTeamId);
        return this._applicationBus.dispatch(new TeamSelectedEvent(selectedTeamId));
      }),
      map(() => void 0),
    );
  }

  hasTeam(): Observable<boolean> {
    return this._allTeams$.pipe(map(teams => teams && teams.length > 0));
  }

  getAllTeamQuery(): Observable<TeamQuery[]> {
    return this._allTeams$.pipe(
      map(teams => teams && teams.map(team => TeamQuery.fromTeamDTO(team))),
    );
  }

  getCurrentTeamQuery(): Observable<TeamQuery> {
    return this._getsCobiroProContextQuery
      .getContext()
      .pipe(
        map(context =>
          context.selectedTeamId
            ? new TeamQuery(
                context.selectedTeamId,
                context.selectedTeamName,
                context.selectedTeamAvatar,
                context.selectedTeamUserRole,
              )
            : undefined,
        ),
      );
  }

  private _load(criteria = {}): Observable<TeamDTO[]> {
    return this._getAllTeam.getsAll(criteria).pipe(
      tap((teams: TeamDTO[]) => {
        this._allTeamsSubject$.next(teams);
        this._dispatchTeamsLoadedEvent(teams);
      }),
      finalize(() => this._isLoading$.next(false)),
    );
  }

  private _dispatchTeamsLoadedEvent(teamsDto: TeamDTO[]): void {
    this._teamsLoadedDispatcher.dispatch(
      new CobiroProTeamsLoadedEvent(
        teamsDto.map(teamDto => ({
          id: teamDto.id,
          name: teamDto.name,
          avatar: teamDto.avatar,
          role: teamDto.role,
        })),
      ),
    );
  }
}
