import { NgModule } from '@angular/core';
import { SetGoogleAdsLinkingComponentModule } from '../../../adapters/primary/ui/modals/label-manager-google-ads-linking/set-google-ads-linking.component-module';
import { LabelManagerNoIntegrationComponentModule } from '../../../adapters/primary/ui/modals/label-manager-no-integration/label-manager-no-integration.component-module';
import { LabelManagerNonCssIntegrationComponentModule } from '../../../adapters/primary/ui/modals/label-manager-non-css-integration/label-manager-non-css-integration.component-module';
import { CLEANS_LM_REPORT_DATE_QUERY } from '../../ports/primary/cleans-lm-report-date.query-port';
import { CLOSES_LM_RECOMMENDATION_QUERY } from '../../ports/primary/closes-lm-recommendation.query-port';
import { CREATES_LM_SIMULATION_QUERY } from '../../ports/primary/creates-lm-simulation.query-port';
import { CREATES_NON_CSS_INTEGRATION_QUERY } from '../../ports/primary/creates-non-css-integration.query-port';
import { GETS_CURRENT_LM_RECOMMENDATION_QUERY } from '../../ports/primary/gets-current-lm-recommendation.query-port';
import { GETS_GOOGLE_ADS_LINKING_STATUS_QUERY_PORT } from '../../ports/primary/gets-google-ads-linking-status.query-port';
import { GETS_INSTALLED_APPS_QUERY_PORT } from '../../ports/primary/gets-installed_apps.query-port';
import { GETS_INTEGRATION_ID_QUERY } from '../../ports/primary/gets-integration-id.query-port';
import { GETS_INTEGRATION_TYPE_QUERY } from '../../ports/primary/gets-integration-type.query-port';
import { GETS_LABEL_MANAGER_CONFIG_QUERY_PORT } from '../../ports/primary/gets-label-manager-config.query-port';
import { GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT } from '../../ports/primary/gets-label-manager-error-message.query-port';
import { GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY } from '../../ports/primary/gets-label-manager-loader-message.query-port';
import { GETS_LABEL_MANAGER_META_QUERY_PORT } from '../../ports/primary/gets-label-manager-meta.query-port';
import { GETS_LABEL_MANAGER_REPORT_LENGTH_QUERY_PORT } from '../../ports/primary/gets-label-manager-report-length.query-port';
import { GETS_LABEL_MANAGER_REPORT_QUERY_PORT } from '../../ports/primary/gets-label-manager-report.query-port';
import { IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT } from '../../ports/primary/is-label-manager-report-loading.query-port';
import { LINKS_GOOGLE_ADS_ACCOUNT_QUERY_PORT } from '../../ports/primary/links-google-ads-account.query-port';
import { SAVES_LABEL_MANAGER_CONFIG_COMMAND_PORT } from '../../ports/primary/saves-label-manager-config.command-port';
import { SETS_CURRENT_LM_REPORT_QUERY } from '../../ports/primary/sets-current-lm-report.query-port';
import { SETS_INTEGRATION_QUERY } from '../../ports/primary/sets-integration.query-port';
import { SETS_LABEL_MANAGER_CONFIG_COMMAND_PORT } from '../../ports/primary/sets-label-manager-config.command-port';
import { SHOW_CONFIG_STEPPER_QUERY_PORT } from '../../ports/primary/shows-config-stepper.query-port';
import { SHOWS_SIMULATION_OPTION_QUERY_PORT } from '../../ports/primary/shows-simulation-option.query-port';
import { SHOW_START_PAGE_QUERY_PORT } from '../../ports/primary/shows-start-page.query-port';
import { STARTS_INTEGRATION_PROCESS_COMMAND } from '../../ports/primary/starts-integration-process.command-port';
import { LabelManagerIntegrationState } from './label-manager-integration.state';
import { GETS_LABEL_MANAGER_SEGMENTS_QUERY_PORT } from '../../ports/primary/gets-label-manager-segments.query-port';
import { LOADS_LABEL_MANAGER_REPORT_QUERY_PORT } from '../../ports/primary/loads-label-manager-report.query-port';

@NgModule({
  imports: [
    LabelManagerNoIntegrationComponentModule,
    SetGoogleAdsLinkingComponentModule,
    LabelManagerNonCssIntegrationComponentModule,
  ],
  providers: [
    LabelManagerIntegrationState,
    {
      provide: GETS_LABEL_MANAGER_META_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_INSTALLED_APPS_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_GOOGLE_ADS_LINKING_STATUS_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: LINKS_GOOGLE_ADS_ACCOUNT_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_LABEL_MANAGER_CONFIG_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SETS_LABEL_MANAGER_CONFIG_COMMAND_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SHOW_CONFIG_STEPPER_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SHOW_START_PAGE_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: STARTS_INTEGRATION_PROCESS_COMMAND,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_LABEL_MANAGER_REPORT_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_INTEGRATION_ID_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_INTEGRATION_TYPE_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SETS_INTEGRATION_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: CREATES_NON_CSS_INTEGRATION_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: CREATES_LM_SIMULATION_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SHOWS_SIMULATION_OPTION_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_LABEL_MANAGER_REPORT_LENGTH_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: CLEANS_LM_REPORT_DATE_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SAVES_LABEL_MANAGER_CONFIG_COMMAND_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: SETS_CURRENT_LM_REPORT_QUERY,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: GETS_LABEL_MANAGER_SEGMENTS_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
    {
      provide: LOADS_LABEL_MANAGER_REPORT_QUERY_PORT,
      useExisting: LabelManagerIntegrationState,
    },
  ],
})
export class LabelManagerIntegrationStateModule {}
