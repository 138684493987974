import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Inject,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  animationFrameScheduler,
  debounceTime,
  take,
  takeUntil,
} from 'rxjs';
import {
  ShowConfigStepperQueryPort,
  SHOW_CONFIG_STEPPER_QUERY_PORT,
} from '../../../../application/ports/primary/shows-config-stepper.query-port';
import {
  GETS_CURRENT_LM_RECOMMENDATION_QUERY,
  GetsCurrentLMRecommendationQueryPort,
} from '../../../../application/ports/primary/gets-current-lm-recommendation.query-port';
import {
  LOADS_LABEL_MANAGER_REPORT_QUERY_PORT,
  LoadsLabelManagerReportQueryPort,
} from '../../../../application/ports/primary/loads-label-manager-report.query-port';

@Component({
  selector: 'lib-label-manager-integration',
  templateUrl: './label-manager-integration.component.html',
  styleUrls: ['./label-manager-integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerIntegrationComponent implements OnInit, OnDestroy {
  private readonly _ngDestroy$ = new Subject<void>();

  constructor(
    @Inject(LOADS_LABEL_MANAGER_REPORT_QUERY_PORT)
    private readonly _loadsLabelManagerReportQueryPort: LoadsLabelManagerReportQueryPort,
    @Inject(SHOW_CONFIG_STEPPER_QUERY_PORT)
    private readonly _showConfigStepperCommandPort: ShowConfigStepperQueryPort,
  ) {}

  ngOnInit(): void {
    this._loadsLabelManagerReportQueryPort.loadReport(false).pipe(take(1)).subscribe();
    this._showConfigStepperCommandPort.showStepper().pipe(take(1)).subscribe();
  }

  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
  }
}
